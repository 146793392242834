import { tableData } from "./table-mock-data";

import Cookies from "js-cookie";
// 加密解密
let Base64 = require("js-base64").Base64;

const TaskStatusMap = {
  0: "todo",
  1: "progress",
  2: "completed",
  "-1": "overdue",
};


  export const AttributeType = [
    // FOOD#食品 FARM#农产品
    { label: '食品', value: 'FOOD'},
    { label: '农产品', value: 'FARM'}
  ]
  export const StorageType = [
    // ROOM_TEMPERATURE#常温 REFRIGERATION#冷藏 FREEZING#冷冻 CHRONIC#长期
    { label: '常温', value: 'ROOM_TEMPERATURE'},
    { label: '冷藏', value: 'REFRIGERATION'},
    { label: '冷冻', value: 'FREEZING'},
    { label: '长期', value: 'CHRONIC'}
  ]
  export const ShelfLifeUnit = [
    // HOUR#小时 DAY#天 MONTH#月 YEAR#年
    { label: '小时', value: 'HOUR'},
    { label: '天', value: 'DAY'},
    { label: '月', value: 'MONTH'},
    { label: '年', value: 'YEAR'}
  ]
  export const SpecificationUnit =[
      // ML#毫升 L#升 G#克 KG#千克
    { label: 'ML', value: 'ML'},
    { label: 'L', value: 'L'},
    { label: '毫克', value: 'MG'},
    { label: 'g', value: 'G'},
    { label: '斤', value: 'JIN'},
    { label: 'kg', value: 'KG'},
    { label: '吨', value: 'T'}
  ]

  export const UnitType =[
    // 斤、瓶、盒、袋、罐、包、箱、件
  { label: '斤', value: '斤'},
  { label: '瓶', value: '瓶'},
  { label: '盒', value: '盒'},
  { label: '袋', value: '袋'},
  { label: '杯', value: '杯'},
  { label: '罐', value: '罐'},
  { label: '桶', value: '桶'},
  { label: '包', value: '包'},
  { label: '箱', value: '箱'},
  { label: '件', value: '件'},
  { label: '个', value: '个'},
  { label: '根', value: '根'}
]

  export const RoleType =[
      // ADMIN#管理员 BUYER#采购员 DELIVERYER#配送员 CLIENTELE#客户 SUPPLIER#供应商
    { label: '管理员', value: 'ADMIN'},
    { label: '采购员', value: 'BUYER'},
    { label: '配送员', value: 'DELIVERYER'},
    { label: '客户', value: 'CLIENTELE'},
    { label: '供应商', value: 'SUPPLIER'}
  ]

  

  const map2Target = (source, key, value) => {
  let target = {};
  let temp = (source || []).map((item) => {
    if (item[key] == value) {
      target = item;
      return target;
    }
  });
  return target;
};

export const sys_def = {
  attributeType: AttributeType,
  storageType: StorageType,
  shelfLifeUnit: ShelfLifeUnit,
  specificationUnit: SpecificationUnit,
  roleType: RoleType,
  unitType: UnitType,

  map2StudentRange(value) {
    return map2Target(StudentRange, "value", value);
  },
  map2StudentCardType(value) {
    return map2Target(StudentCardType, "value", value);
  },
  transform(key, value) {
    let result = map2Target(this[key], "value", value);
    return result
  }
};
