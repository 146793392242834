import Vue from "vue";
import VueRouter from "vue-router";

import {
  UserUtil
} from "@/utils/user";

// const routerPush = VueRouter.prototype.push;

// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return routerPush.call(this, location, onResolve, onReject);
//   return routerPush.call(this, location).catch((error) => error);
// }

Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "main",
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("../pages/login/index.vue"),
  },
  {
    path: "/main",
    name: "main",
    component: () => import("../pages/index.vue"),
    redirect: "/home",
    children: [{
        path: "/home",
        name: "home",
        component: () => import("../pages/home/home.vue"),
      },
      {
        path: "/notifys",
        name: "notify",
        component: () => import("../pages/notify/index.vue"),
      },
      {
        path: "/inquirys",
        name: "inquiry",
        component: () => import("../pages/inquiry/index.vue"),
      },
      {
        path: "/trend",
        name: "trend",
        component: () => import("../pages/trend/index.vue"),
      },
      {
        path: "/goods",
        name: "goods",
        component: () => import("../pages/goods/index.vue"),
      },
      {
        path: "/categorys",
        name: "category",
        component: () => import("../pages/goods/category.vue"),
      },
      {
        path: "/quotation",
        name: "quotation",
        component: () => import("../pages/quotation/index.vue"),
      },
      {
        path: "/clienteles",
        name: "clientele",
        component: () => import("../pages/clientele/index.vue"),
      },
      {
        path: "/suppliers",
        name: "supplier",
        component: () => import("../pages/supplier/index.vue"),
      },
      {
        path: "/purchase-orders",
        name: "purchaseOrder",
        component: () => import("../pages/purchase-order/index.vue"),
      },
      {
        path: "/purchase/:id",
        name: "purchase",
        component: () => import("../pages/purchase-order/purchase.vue"),
      },
      {
        path: "/employees",
        name: "employee",
        component: () => import("../pages/employee/index.vue"),
      },
      {
        path: "/setting",
        name: "setting",
        component: () => import("../pages/setting/index.vue"),
      }
    ],
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 挂载路由守卫
router.beforeEach((to, from, next) => {
  let toPath = to.path;
  let hasLogged = false;
  try {
    let user = UserUtil.get();
    if (user.token && user.token != "") {
      hasLogged = true;
    }
  } catch (e) {
    console.log(e);
    hasLogged = false;
  }
  // hasLogged = false
  console.log(`has logged : ${hasLogged}, to : ${toPath}`);
  if (!hasLogged && toPath != "/sign-in") {
    return next("/sign-in");
  } else if (hasLogged && toPath == "/sign-in") {
    return next("/home");
  }
  return next();
});

export default router;