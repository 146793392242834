const WEEK_MAP = {
  0: {
    zh: "周日",
    en: "Sunday",
    short: "Sun."
  },
  1: {
    zh: "周一",
    en: "Monday",
    short: "Mon."
  },
  2: {
    zh: "周二",
    en: "Tuesday",
    short: "Tues."
  },
  3: {
    zh: "周三",
    en: "Wednesday",
    short: "Wed."
  },
  4: {
    zh: "周四",
    en: "Thursday",
    short: "Thur."
  },
  5: {
    zh: "周五",
    en: "Friday",
    short: "Fri."
  },
  6: {
    zh: "周六",
    en: "Saturday",
    short: "Sat."
  },
};
export const EZDate = {
  format: (date, fmt = "yyyy-MM-dd hh:mm:ss") => {
    var o = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "h+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1 ?
          o[k] :
          ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  },
  formatWeek: (date = new Date()) => {
    let fmtDate = EZDate.format(date, "yyyy/MM/dd");
    return fmtDate + " " + WEEK_MAP[date.getDay()].zh;
  },
  from: (timestamp) => {
    return new Date(timestamp);
  },
  from: (dateStr, fmt = "yyyy-MM-dd hh:mm:ss") => {
    return new Date(dateStr);
  },
  getDateDiff(date, fmt = "yyyy-MM-dd hh:mm:ss") {
    // 时间字符串转时间戳
    var timestamp = this.from(date, fmt).getTime();
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var halfamonth = day * 15;
    var month = day * 30;
    var year = day * 365;
    var now = new Date().getTime();
    var diffValue = now - timestamp;
    var result;
    if (diffValue < 0) {
      return;
    }
    var yearC = diffValue / year;
    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    // if (yearC >= 1) {
    //     result = "" + parseInt(yearC) + "年前";
    // } else if (monthC >= 1) {
    //     result = "" + parseInt(monthC) + "月前";
    // } else
    if (weekC >= 1) {
      // result = "" + parseInt(weekC) + "周前";
      result = date;
    } else if (dayC >= 1) {
      result = "" + parseInt(dayC) + "天前";
    } else if (hourC >= 1) {
      result = "" + parseInt(hourC) + "小时前";
    } else if (minC >= 1) {
      result = "" + parseInt(minC) + "分钟前";
    } else result = "刚刚";
    return result;
  },

  getDay(year, month) {
    if (year != "" && month != "") {
      var firstDay = new Date(year, month - 1, 1); //这个月的第一天
      var currentMonth = firstDay.getMonth(); //取得月份数
      var lastDay = new Date(firstDay.getFullYear(), currentMonth + 1, 0); //是0而不是-1
      return [firstDay, lastDay];
    }
  },

  timeCut(chinaStandard) {
    var date = new Date(chinaStandard);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var h = date.getHours();
    var minute = date.getMinutes();
    minute = minute < 10 ? "0" + minute : minute;
    let time = y + "-" + m + "-" + d;
    return time;
  },
  getCurrentMonthTime() {
    return this.getMonthTime(new Date())
  },
  getMonthTime(date) {

    // 设置为日期1号
    date.setDate(1);
    // 获取当前月份(date.getMonth()返回的是0-11)
    let month = parseInt(date.getMonth() + 1);
    // 获取当月第一天日期
    let startDay = date.getDate();
    // 获取当前月的最后一天。参数0代表上个月的最后一天
    const endOfMonth = new Date(date.getFullYear(), month, 0).getDate();
    // 设置日期为当前月的最后一天
    date.setDate(endOfMonth);
    // 获取当月最后一天日期
    let endDay = date.getDate();
    if (month < 10) month = "0" + month;
    if (startDay < 10) startDay = "0" + startDay;
    if (endDay < 10) endDay = "0" + endDay;
    const startTime = date.getFullYear() + "-" + month + "-" + startDay;
    const endTime = date.getFullYear() + "-" + month + "-" + endDay;
    return [startTime, endTime];
  },
  getCurrentWeekTime() {
    return this.getWeekTime(new Date())
  },
  getWeekTime(date) {
    // 获取周几 0-6, 0表示周日
    let week = date.getDay();
    if (week == 0) {
      week = 7
    }

    const one_day_timestamp = 24 * 60 * 60 * 1000
    let leftOffset = (week - 1),
      rightOffset = (7 - week)
    let timestamp = date.getTime()
    let startDate = new Date(timestamp - (leftOffset * one_day_timestamp)),
      endDate = new Date(timestamp + (rightOffset * one_day_timestamp))

    return [this.format(startDate, "yyyy-MM-dd"), this.format(endDate, "yyyy-MM-dd")];
  },
};